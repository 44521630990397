import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { ViewRoles } from '../../app.menu';
import { RoleEnum } from '../../features/admin/users/role-enum-model';
import { User } from '../../features/admin/users/user.model';
import { UserRole } from '../../features/admin/users/user-roles.model';
import { API_URL } from '../api-url.token';
import { Sequences } from '../sequences';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private router = inject(Router);
  private apiUrl = inject(API_URL);
  private http = inject(HttpClient);

  user = signal<User | null>(this.getUserFromLocalStorage());

  get userIsLoggedIn(): boolean {
    return this.getToken() != null;
  }

  get userIsAdmin(): boolean {
    if (!this.userIsLoggedIn) return false;
    return this.user()!.isAdmin;
  }

  userHasRole(entityId: number, requiredRole: RoleEnum): boolean {
    if (!this.userIsLoggedIn) return false;
    return this.hasRole(this.user()!.roles, entityId, requiredRole);
  }

  private hasRole(roles: UserRole[] | undefined, entityId: number, requiredRole: RoleEnum): boolean {
    if (!roles) return false;
    return roles.some(userRole => userRole.entityId === entityId && (userRole.role & requiredRole) === requiredRole) ?? false;
  }

  get userViewRoles(): ViewRoles[] {
    if (!this.userIsLoggedIn) return [ViewRoles.NotLoggedIn];

    let roles: ViewRoles[] = [];

    if (this.user()!.isAdmin) {
      // if (this.userIsAdmin) {
      roles.push(ViewRoles.Admin);
    }

    this.user()!.roles!.map(role => {
      if (role.entityId >= Sequences.AffiliateStartId && role.entityId < Sequences.AffiliateStartId + Sequences.SequenceSize) {
        if (!roles.includes(ViewRoles.Affiliate)) {
          roles.push(ViewRoles.Affiliate);
        }
      } else if (role.entityId >= Sequences.BrokerStartId && role.entityId < Sequences.BrokerStartId + Sequences.SequenceSize) {
        if (!roles.includes(ViewRoles.Broker)) {
          roles.push(ViewRoles.Broker);
        }
      } else if (role.entityId >= Sequences.LenderStartId && role.entityId < Sequences.LenderStartId + Sequences.SequenceSize) {
        if (!roles.includes(ViewRoles.Lender)) {
          roles.push(ViewRoles.Lender);
        }
      }
    });

    return roles;
  }

  getToken(): string | null {
    var token = localStorage.getItem('jwt_token');
    var expires = localStorage.getItem('jwt_expires');
    var user = localStorage.getItem('user');

    if (token == null || expires == null || user == null) {
      console.log('getToken() == null');
      this.cleanup();
      return null;
    }

    var expiresDate = new Date(expires);
    var now = new Date();
    if (expiresDate <= now) {
      console.log('getToken() == expired');
      this.cleanup();
      return null;
    }

    return token;
  }

  // Sign-up
  signUp(user: User): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/users/register`, user).pipe(catchError(this.handleError));
  }

  // Sign-in
  signIn(user: User) {
    this.http
      .post<any>(`${this.apiUrl}/api/users/authenticate`, user)
      .pipe(take(1), catchError(this.handleError))
      .subscribe((res: any) => {
        console.log('signIn() request succeeded =', res);

        localStorage.setItem('jwt_token', res.result.token);
        localStorage.setItem('jwt_expires', res.result.expires);
        // TODO: Implement refresh token
        localStorage.setItem('jwt_refresh', res.result.refresh);

        // Set the user object and save it to local storage.
        this.user.set(res.result.user);
        // console.log('signIn() this.user() =', this.user());
        localStorage.setItem('user', JSON.stringify(this.user()));

        // TODO: handle any redirects from the URL, etc.

        this.router.navigate(['dashboard']);
      });
  }

  doLogout() {
    this.cleanup();
    this.router.navigate(['login']);
  }

  // User profile
  getUserProfile(id: any): Observable<any> {
    console.log('getUserProfile() id =', id);

    return this.http.get(`${this.apiUrl}/api/users/${id}`).pipe(
      map(res => res || {}),
      // TODO: Change to use the Angular built-in..
      catchError(this.handleError)
    );
  }

  // Error
  handleError(error: HttpErrorResponse) {
    console.log('handleError() error =', error);
    console.log('handleError() error.error =', error.error);

    if (error.status === 0) {
      return throwError(() => 'Could not connect to the server. Please check your internet connection.');
    }

    let msg = '';

    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(() => msg);
  }

  private cleanup() {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('jwt_expires');
    localStorage.removeItem('jwt_refresh');
    localStorage.removeItem('user');
  }

  private getUserFromLocalStorage(): User | null {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;
  }
}
